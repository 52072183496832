import type { AppType } from "next/app";
import { Inter } from "next/font/google";
import { Analytics } from "@vercel/analytics/react";
import PlausibleProvider from "next-plausible";

import "../styles/globals.css";

import { config } from "@fortawesome/fontawesome-svg-core";

import { ThemeProvider } from "~/components/theme/ThemeProvider";
import { api } from "~/utils/api";

import "@fortawesome/fontawesome-svg-core/styles.css";

config.autoAddCss = false;

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
});

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <PlausibleProvider domain="vitalroutines.ai">
      <ThemeProvider>
        <main className={inter.variable}>
          <Component {...pageProps} />
        </main>
      </ThemeProvider>
      <Analytics />
    </PlausibleProvider>
  );
};

export default api.withTRPC(MyApp);
